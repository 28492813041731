#piececito {
    font-family: sans-serif;
    position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
    background-color: black;
}
